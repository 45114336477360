import React, { Component } from "react";
import { Card, Row, Col, Divider } from "antd";

export default class PrivacyPolicies extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>Privacy Policy</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Card
              bordered
              title={null}
              bodyStyle={{
                padding: "0px",
              }}
              className="padding-card-whyjoin"
            >
              <Row>
                <p>
                  Protecting your private information is our priority. This
                  Statement of Privacy applies to www.thatsmytown.com, and
                  That's myTown, Inc. and governs data collection and usage.
                  For the purposes of this Privacy Policy, unless otherwise
                  noted, all references to That's My Town, Inc. include
                  www.thatsmytown.com. The That's My Town, Inc. website is a
                  Real Estate Search site. By using the That's My Town, Inc.
                  website, you consent to the data practices described in this
                  statement. <br /> 
                  <h3>Collection of your Personal Information </h3>
                  In order to better provide you with products and services
                  offered, That's My Town, Inc. may collect personally
                  identifiable information, such as your:{" "}
                </p>
                <br /> 
                <p>
                  - First and Last Name <br />- E-mail Address <br />- Phone
                  Number <br />
                  If you purchase That's My Town, Inc.'s products and services,
                  we collect billing and credit card information. This
                  information is used to complete the purchase transaction.{" "}
                </p>
                 
                <p>
                  Please keep in mind that if you directly disclose personally
                  identifiable information or personally sensitive data through
                  That's My Town, Inc.'s public message boards, this
                  information may be collected and used by others.{" "}
                </p>
                 
                <p>
                  We do not collect any personal information about you unless
                  you voluntarily provide it to us. However, you may be required
                  to provide certain personal information to us when you elect
                  to use certain products or services. These may include: (a)
                  registering for an account; (b) entering a sweepstakes or
                  contest sponsored by us or one of our partners; (c) signing up
                  for special offers from selected third parties; (d) sending us
                  an email message; (e) submitting your credit card or other
                  payment information when ordering and purchasing products and
                  services. To wit, we will use your information for, but not
                  limited to, communicating with you in relation to services
                  and/or products you have requested from us. We also may gather
                  additional personal or non-personal information in the future.{" "}
                  <br /> <h3>Use of your Personal Information </h3>
                  That's My Town, Inc. collects and uses your personal
                  information to operate and deliver the services you have
                  requested.{" "}
                </p>
                 
                <p>
                  That's My Town, Inc. may also use your personally
                  identifiable information to inform you of other products or
                  services available from That's My Town, Inc. and its
                  affiliates. <br /> 
                  <h3>Sharing Information with Third Parties</h3>
                  That's My Town, Inc. does not sell, rent or lease its
                  customer lists to third parties.
                </p>
                 
                <p>
                  That's My Town, Inc. may, from time to time, contact you on
                  behalf of external business partners about a particular
                  offering that may be of interest to you. In those cases, your
                  unique personally identifiable information (e-mail, name,
                  address, telephone number) is transferred to the third party.
                  That's My Town, Inc. may share data with trusted partners to
                  help perform statistical analysis, send you email or postal
                  mail, provide customer support, or arrange for deliveries. All
                  such third parties are prohibited from using your personal
                  information except to provide these services to That's My Town
                  , Inc., and they are required to maintain the
                  confidentiality of your information.{" "}
                </p>
                 
                <p>
                  That's My Town, Inc. may disclose your personal information,
                  without notice, if required to do so by law or in the good
                  faith belief that such action is necessary to: (a) conform to
                  the edicts of the law or comply with legal process served on
                  That's My Town, Inc. or the site; (b) protect and defend the
                  rights or property of That's My Town, Inc.; and/or (c) act
                  under exigent circumstances to protect the personal safety of
                  users of That's My Town, Inc., or the public. <br /> 
                  <h3>
                    Opt-Out of Disclosure of Personal Information to Third
                    Parties
                  </h3>
                  In connection with any personal information we may disclose to
                  a third party for a business purpose, you have the right to
                  know:{" "}
                </p>
                <p>
                  • The categories of personal information that we disclosed
                  about you for a business purpose.{" "}
                </p>
                 
                <br />
                You have the right under the California Consumer Privacy Act of
                2018 (CCPA) and certain other privacy and data protection laws,
                as applicable, to opt-out of the disclosure of your personal
                information. If you exercise your right to opt-out of the
                disclosure of your personal information, we will refrain from
                disclosing your personal information, unless you subsequently
                provide express authorization for the disclosure of your
                personal information. To opt-out of the disclosure of your
                personal information, visit this Web page
                https://thatsmytown.com/contactUs. <br /> 
                <h3>Tracking User Behavior </h3>
                That's My Town, Inc. may keep track of the websites and pages
                our users visit within That's My Town, Inc., in order to
                determine what That's My Town, Inc. services are the most
                popular. This data is used to deliver customized content and
                advertising within That's My Town, Inc. to customers whose
                behavior indicates that they are interested in a particular
                subject area. <br /> 
                <h3>Automatically Collected Information </h3>
                Information about your computer hardware and software may be
                automatically collected by That's My Town, Inc.. This
                information can include: your IP address, browser type, domain
                names, access times and referring website addresses. This
                information is used for the operation of the service, to
                maintain quality of the service, and to provide general
                statistics regarding use of the That's My Town, Inc. website.{" "}
                <br /> <h3>Use of Cookies </h3>
                The That's My Town, Inc. website may use "cookies" to help you
                personalize your online experience. A cookie is a text file that
                is placed on your hard disk by a web page server. Cookies cannot
                be used to run programs or deliver viruses to your computer.
                Cookies are uniquely assigned to you, and can only be read by a
                web server in the domain that issued the cookie to you. <br />
                One of the primary purposes of cookies is to provide a
                convenience feature to save you time. The purpose of a cookie is
                to tell the Web server that you have returned to a specific
                page. For example, if you personalize That's My Town, Inc.
                pages, or register with That's My Town, Inc. site or services,
                a cookie helps That's My Town, Inc. to recall your specific
                information on subsequent visits. This simplifies the process of
                recording your personal information, such as billing addresses,
                shipping addresses, and so on. When you return to the same Homes
                Listed By, Inc. website, the information you previously provided
                can be retrieved, so you can easily use the That's My Town,
                Inc. features that you customized.
                <br />
                You have the ability to accept or decline cookies. Most Web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer. If
                you choose to decline cookies, you may not be able to fully
                experience the interactive features of the That's My Town, Inc.
                services or websites you visit. <br /> <h3>Links </h3>
                This website contains links to other sites. Please be aware that
                we are not responsible for the content or privacy practices of
                such other sites. We encourage our users to be aware when they
                leave our site and to read the privacy statements of any other
                site that collects personally identifiable information. <br /> 
                <h3>Security of your Personal Information </h3>
                That's My Town, Inc. secures your personal information from
                unauthorized access, use, or disclosure. That's My Town, Inc.
                uses the following methods for this purpose: <br />
                <p> - SSL Protocol </p> 
                <p>
                  When personal information (such as a credit card number) is
                  transmitted to other websites, it is protected through the use
                  of encryption, such as the Secure Sockets Layer (SSL)
                  protocol.{" "}
                </p>
                 
                <p>
                  We strive to take appropriate security measures to protect
                  against unauthorized access to or alteration of your personal
                  information. Unfortunately, no data transmission over the
                  Internet or any wireless network can be guaranteed to be 100%
                  secure. As a result, while we strive to protect your personal
                  information, you acknowledge that: (a) there are security and
                  privacy limitations inherent to the Internet which are beyond
                  our control; and (b) security, integrity, and privacy of any
                  and all information and data exchanged between you and us
                  through this Site cannot be guaranteed. <br /> 
                  <h3>Right to Deletion </h3>
                  Subject to certain exceptions set out below, on receipt of a
                  verifiable request from you, we will:{" "}
                </p>
                <p>• Delete your personal information from our records; and </p>
                <p>
                  • Direct any service providers to delete your personal
                  information from their records.{" "}
                </p>
                 
                <p>
                  Please note that we may not be able to comply with requests to
                  delete your personal information if it is necessary to:{" "}
                </p>
                <p>
                  • Complete the transaction for which the personal information
                  was collected, fulfill the terms of a written warranty or
                  product recall conducted in accordance with federal law,
                  provide a good or service requested by you, or reasonably
                  anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform a contract between
                  you and us;{" "}
                </p>
                <p>
                  • Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity; or prosecute those
                  responsible for that activity;
                </p>
                <p>
                  • Debug to identify and repair errors that impair existing
                  intended functionality;{" "}
                </p>
                <p>
                  • Exercise free speech, ensure the right of another consumer
                  to exercise his or her right of free speech, or exercise
                  another right provided for by law;
                </p>
                <p>
                  • Comply with the California Electronic Communications Privacy
                  Act;{" "}
                </p>
                <p>
                  • Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when our
                  deletion of the information is likely to render impossible or
                  seriously impair the achievement of such research, provided we
                  have obtained your informed consent;{" "}
                </p>
                <p>
                  • Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us;{" "}
                </p>
                <p>• Comply with an existing legal obligation; or </p>
                <p>
                  • Otherwise use your personal information, internally, in a
                  lawful manner that is compatible with the context in which you
                  provided the information.
                  <br />  <h3>Children Under Thirteen</h3>
                  That's My Town, Inc. does not knowingly collect personally
                  identifiable information from children under the age of
                  thirteen. If you are under the age of thirteen, you must ask
                  your parent or guardian for permission to use this website.{" "}
                </p>
                 <h3>Opt-Out & Unsubscribe from Third Party Communications </h3>
                <p>
                  We respect your privacy and give you an opportunity to opt-out
                  of receiving announcements of certain information. Users may
                  opt-out of receiving any or all communications from
                  third-party partners of That's My Town, Inc. by contacting us
                  here:{" "}
                </p>
                <p>- Web page: https://thatsmytown.com/contactUs </p>
                <p>- Email: thatsmytown@gmail.com </p>
                <p>- Phone: 9784001617 </p> <p>E-mail Communications </p>
                <p>
                  From time to time, That's My Town, Inc. may contact you via
                  email for the purpose of providing announcements, promotional
                  offers, alerts, confirmations, surveys, and/or other general
                  communication.{" "}
                </p>
                 
                <p>
                  If you would like to stop receiving marketing or promotional
                  communications via email from That's My Town, Inc., you may
                  opt out of such communications by Clicking on unsubscribe
                  button. <br /> <h3>External Data Storage Sites </h3>
                  We may store your data on servers provided by third party
                  hosting vendors with whom we have contracted.
                  <br /> <h3>Changes to this Statement </h3>
                  That's My Town, Inc. reserves the right to change this
                  Privacy Policy from time to time. We will notify you about
                  significant changes in the way we treat personal information
                  by sending a notice to the primary email address specified in
                  your account, by placing a prominent notice on our website,
                  and/or by updating any privacy information. Your continued use
                  of the website and/or Services available after such
                  modifications will constitute your: (a) acknowledgment of the
                  modified Privacy Policy; and (b) agreement to abide and be
                  bound by that Policy.{" "}
                </p>
                 <h3>Contact Information </h3>
                <h4>
                  That's My Town, Inc. welcomes your questions or comments
                  regarding this Statement of Privacy. If you believe that Homes
                  Listed By, Inc. has not adhered to this Statement, please
                  contact That's My Town, Inc. at:{" "}
                </h4>
                <p>
                  That's My Town, Inc. <br />
                  14 Linda Drive <br />
                  Westminster, Massachusetts 01473 <br />
                   Email Address: <br />
                  thatsmytown@gmail.com <br />
                  Telephone number: <br />
                  9784001617
                  <br />
                  Effective as of July 01, 2021{" "}
                </p>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
