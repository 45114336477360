import React, { Component } from "react";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  MoreOutlined,
  CloseOutlined,
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Drawer } from "antd";

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMoreOptions: false,
      enableHomeOptions: false,
    };
  }

  onClickMenu = (menu) => {
    window.location.replace(menu);
  };

  render() {
    const { enableMoreOptions, enableHomeOptions } = this.state;
    const bottomNavItems = [
      {
        title: "Homes",
        icon: <HomeOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <HomeOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.setState({
            enableHomeOptions: true,
          });
        },
      },
      {
        title: "Buyers",
        icon: <TeamOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <TeamOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/buyers");
        },
      },
      {
        title: "Agents",
        icon: (
          <UserSwitchOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
        ),
        activeIcon: (
          <UserSwitchOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/agent");
        },
      },

      {
        title: "Lenders",
        icon: (
          <DollarCircleOutlined
            style={{ fontSize: "24px", cursor: "pointer" }}
          />
        ),
        activeIcon: (
          <DollarCircleOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/lenders");
        },
      },

      {
        title: "Market",
        icon: <ShopOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <ShopOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/marketplace");
        },
      },
      {
        title: "More",
        icon: <MoreOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <MoreOutlined
            style={{ fontSize: "24px", color: "#fc572b", cursor: "pointer" }}
          />
        ),
        onClick: () =>
          this.setState({
            enableMoreOptions: true,
          }),
      },
    ];

    return (
      <div>
        <Drawer
          title={null}
          placement="bottom"
          height={120}
          // mask={true}
          closable={false}
          getContainer={false}
          onClose={() => () =>
            this.setState({
              enableHomeOptions: false,
            })}
          visible={enableHomeOptions}
          headerStyle={{ margin: "10%" }}
          bodyStyle={{
            paddingBottom: 100,
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{
              padding: "10px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div>
              <h4>Homes</h4>
            </div>
            <div
              onClick={() => {
                this.setState({
                  enableHomeOptions: false,
                });
              }}
            >
              <CloseOutlined style={{ fontSize: "18px" }} />
            </div>
          </Row>

          <Col span={24} style={{ margin: "10px" }}>
            <Row>
              <Col span={12}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/homes"
                        ? "#fc572b"
                        : "#FFF",

                    color:
                      window.location.pathname === "/homes"
                        ? "#FFF"
                        : "#fc572b",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/homes");
                  }}
                >
                  On & Off MLS Homes
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/offMLSHomes"
                        ? "#fc572b"
                        : "#FFF",
                    color:
                      window.location.pathname === "/offMLSHomes"
                        ? "#FFF"
                        : "#fc572b",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/offMLSHomes");
                  }}
                >
                  Off MLS Homes
                </Button>
              </Col>
            </Row>
          </Col>
        </Drawer>

        <Drawer
          title={null}
          placement="bottom"
          height={160}
          // mask={true}
          closable={false}
          getContainer={false}
          onClose={() => () =>
            this.setState({
              enableMoreOptions: false,
            })}
          visible={enableMoreOptions}
          headerStyle={{ margin: "10%" }}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
          >
            <div>
              <h4>Quick Menu</h4>
            </div>
            <div
              onClick={() => {
                this.setState({
                  enableMoreOptions: false,
                });
              }}
            >
              <CloseOutlined style={{ fontSize: "18px" }} />
            </div>
          </Row>

          <Col span={24} style={{ margin: "10px" }}>
            <Row>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/about"
                        ? "#fc572b"
                        : "#FFF",
                    color:
                      window.location.pathname === "/about"
                        ? "#FFF"
                        : "#fc572b",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/about");
                  }}
                >
                  About Us
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/whyJoinUs"
                        ? "#fc572b"
                        : "#FFF",

                    color:
                      window.location.pathname === "/whyJoinUs"
                        ? "#FFF"
                        : "#fc572b",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/whyJoinUs");
                  }}
                >
                  Why Join Us
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/contactUs"
                        ? "#fc572b"
                        : "#FFF",

                    color:
                      window.location.pathname === "/contactUs"
                        ? "#FFF"
                        : "#fc572b",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/contactUs");
                  }}
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Col>

          {/* <Row justify="space-around">
            <Col
              span={8}
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#eff2f5",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <Row justify="center">
                  <PhoneOutlined
                    style={{
                      color: "#3045d2",
                      fontWeight: "bold",
                      transform: "rotate(100deg)",
                      cursor: "pointer",
                    }}
                  />
                </Row>
                <Row justify="center">
                  <span
                    style={{
                      color: "#3045d2",
                      fontWeight: "bold",
                      marginTop: "5px",
                    }}
                  >
                    Phone
                  </span>
                </Row>
              </div>
            </Col>

            <Col
              span={8}
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#eff2f5",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <a
                  target="_blank"
                  href={"mailto:thatsmytown@gmail.com"}
                  style={{ textDecoration: "none" }}
                >
                  <Row justify="center">
                    <MailOutlined
                      style={{
                        color: "#3045d2",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    />
                  </Row>
                  <Row justify="center">
                    <span
                      style={{
                        color: "#3045d2",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      E-Mail
                    </span>
                  </Row>
                </a>
              </div>
            </Col>

            <Col
              span={8}
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#eff2f5",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <Row justify="center">
                  <EnvironmentOutlined
                    style={{
                      color: "#3045d2",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  />
                </Row>
                <Row justify="center">
                  <span
                    style={{
                      color: "#3045d2",
                      fontWeight: "bold",
                      marginTop: "5px",
                    }}
                  >
                    Location
                  </span>
                </Row>
              </div>
            </Col>
          </Row> */}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Row justify="space-around">
              <Col>
                <a
                  target="_blank" 
                  href={`https://www.facebook.com/profile.php?id=61557396586017`}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <FacebookFilled
                    style={{ color: "#2a2a2a", fontSize: "20px" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  href={`https://www.instagram.com/thats_my_town/`}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <InstagramOutlined
                    style={{ color: "#2a2a2a", fontSize: "20px" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  href={`https://twitter.com/thatsmytown`}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <TwitterOutlined
                    style={{ color: "#2a2a2a", fontSize: "20px" }}
                  />
                </a>
              </Col>

              <Col>
                <a
                  target="_blank"
                  href={`https://www.youtube.com/@Thatsmytown`}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <YoutubeOutlined
                    style={{ color: "#2a2a2a", fontSize: "20px" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  href={` https://www.linkedin.com/`}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <LinkedinOutlined
                    style={{ color: "#2a2a2a", fontSize: "20px" }}
                  />
                </a>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                backgroundColor: "#2a2a2a",
                marginTop: "10px",
              }}
            >
              <span
                style={{ color: "#FFF", textAlign: "center", fontSize: "12px" }}
              >
                © Copyright That's My Town, Inc.
              </span>
            </Row>
          </div>
        </Drawer>

        <BottomNavigation
          items={bottomNavItems}
          defaultSelected={
            window.location.pathname === "/homes" ||
            window.location.pathname === "/offMLSHomes"
              ? 0
              : window.location.pathname === "/buyers"
              ? 1
              : window.location.pathname === "/agent"
              ? 2
              : window.location.pathname === "/lenders"
              ? 3
              : window.location.pathname === "/marketplace"
              ? 4
              : window.location.pathname === "/about" ||
                window.location.pathname === "/whyJoinUs" ||
                window.location.pathname === "/contactUs"
              ? 5
              : "" || "/"
              ? null
              : 5
          }
          // activeBgColor="#fc572b"
          color="#fc572b"
          activeBgColor="#fff"
          onItemClick={(item) => console.log(item)}
        />
      </div>
    );
  }
}
