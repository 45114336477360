import React, { Component } from "react";
import { Layout, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import logo1 from "../../images/footerLogo.png";
// import logo1 from "../../images/logo1.png";
import {
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import moment from "moment";
import copyrightLogo from "../../images/copyrightLogo.png";
export class OpenFooter extends Component {
  handleclick = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  goTo = () => {
    window.location.replace(`/register`);
  };

  render() {
    const dates = JSON.parse(
      localStorage.getItem("rets_data_last_updated_date")
    );
    let moments = dates && dates.length > 0 && dates.map((d) => moment(d)),
      maxDate = moment.max(moments);
    return (
      <div className="footer" style={{ width: "100%" }}>
        <Layout>
          <Row>
            <Row className="footer-background">
              <p className="footer-text">
                Create a FREE account and begin adding your buy and sell
                profiles now!
              </p>
              <Link to="/register">
                <Button
                  // onClick={() => this.goTo()}
                  type="primary"
                  danger
                  className="footer-button"
                  size="large"
                >
                  <span className="footer-buttonText">Join Now</span>
                </Button>
              </Link>
            </Row>
          </Row>
          <Row
            justify="center"
            style={{
              backgroundColor: "#414555",
              margin: "0",
              padding: "2vw 1vw 1vw 1vw",
            }}
          >
            <img src={logo1} alt="space" className="footer-logo" />
          </Row>
          <Row justify="space-between" className="footer-about">
            <Col className="margin-Top">
              <Link
                to="/about"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>About Us</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link
                to="/whyJoinUs"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>Why Join Us</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link
                to="/contactUs"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>Contact Us</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link
                to="/buyers"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>Buy</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link onClick={this.handleclick}>
                <p style={{ color: "white" }}>Sell</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link
                to="/agent"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>Agents</p>
              </Link>
            </Col>
            <Col className="margin-Top">
              <Link
                to="/marketplace"
                style={{ color: "black", textDecoration: "none" }}
                onClick={this.handleclick}
              >
                <p style={{ color: "white" }}>Marketplace</p>
              </Link>
            </Col>
          </Row>
          <Row justify="space-between" className="icons-padding">
            <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.facebook.com/profile.php?id=61557396586017
                `}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <FacebookFilled className="icon-size-color" />
              </a>
            </Col>
            <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.instagram.com/thats_my_town/`}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <InstagramOutlined className="icon-size-color" />
              </a>
            </Col>
            <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://twitter.com/thatsmytown`}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <TwitterOutlined className="icon-size-color" />
              </a>
            </Col>
            <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.youtube.com/@Thatsmytown
                `}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <YoutubeOutlined className="icon-size-color" />
              </a>
            </Col>
            <Col className="margin-Top">
              <a
                target="_blank"
                href={` https://www.linkedin.com/`}
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <LinkedinOutlined className="icon-size-color" />
              </a>
            </Col>

            {/* <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.skype.com/en/`}
                style={{ textDecoration: "none" }}
              >
                <SkypeOutlined className="icon-size-color" />
              </a>
            </Col> */}
            {/* <Col className="margin-Top">
              <a
                target="_blank"
                href={`https://www.google.com/`}
                style={{ textDecoration: "none" }}
              >
                <GooglePlusOutlined className="icon-size-color" />
              </a>
            </Col> */}
          </Row>
          <Row
            justify="center"
            style={{
              backgroundColor: "#2a2a2a",
              marginTop: 0,
            }}
          >
            <Col span={24}>
              <p className="copyRight" style={{ textAlign: "center" }}>
                © Copyright That's my Town, Inc. All Rights Reserved.
              </p>
            </Col>
            <Col span={24}>
              <p style={{ textAlign: "center" }}>
                <Link to="/privacyPolicies" onClick={this.handleclick}>
                  Privacy Policy
                </Link>{" "}
                <span style={{ color: "#fff" }}>|</span>{" "}
                <Link to="/termsOfService" onClick={this.handleclick}>
                  Terms of Service
                </Link>
              </p>
            </Col>
            <p
              style={{
                textAlign: "center",
                fontSize: "11px",
                color: "#a5a2a2",
                fontFamily: "'Poppins', sans-serif",
                width: "61%",
              }}
            >
              <img
                src={copyrightLogo}
                alt="copyrightLogo"
                width="70px"
                height="32px"
                style={{ verticalAlign: "bottom" }}
              />
              &nbsp;&nbsp; Copyright (2022) New England Real Estate Network,
              Inc. All rights reserved. This information is deemed reliable, but
              not guaranteed. The data relating to real estate displayed on this
              Site comes in part from the IDX Program of NEREN. The information
              being provided is for consumers' personal, non-commercial use and
              may not be used for any purpose other than to identify prospective
              properties consumers may be interested in purchasing. Data last
              updated {moment.utc(maxDate).format("ll")}
              {/* Copyright That's my Town, Inc. All Rights Reserved. */}
              <br />
            </p>
          </Row>
        </Layout>
      </div>
    );
  }
}

export default OpenFooter;
