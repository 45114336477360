import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Popover,
  message,
  Divider,
  notification,
  Drawer,
} from "antd";
import {
  HeartOutlined,
  TwitterOutlined,
  FacebookOutlined,
  WhatsAppOutlined,
  EditTwoTone,
  EllipsisOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import NumberFormat from "react-number-format";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AOS from "aos";
import "aos/dist/aos.css";
import { withAppContext } from "../../store/contextStore";
import { add, noAuthadd } from "../../api/Apis";
import SendMessage from "../../common/SendMessage";
import bedIcon from "../../images/awesome-bed-icon.png";
import bathIcon from "../../images/awesome-bath-icon.png";
import reSize from "../../images/sq-feet-icon.png";
import privatePost from "../../images/protection.png";
import defaultImage from "../../images/defaultImage.jpg";
import SignupForm from "../../containers/RegisterForm";
import LoginForm from "../../containers/LoginForm";
import DeleteRecord from "../../common/DeleteRecord";

export class HomeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      Favorites: [],
      shareUrlTitile: "",
      shareUrl: "",
      homeDetailShareUrl:"",
      mainPhotoUrl: "",
      enableSignupForm: false,
      showLoginSiderForm: false,
      popOverVisible: false,
      EditIcon: false,
    };
  }

  componentDidMount() {
    AOS.init({
      duration: 1500,
    });
    let agentId = JSON.parse(localStorage.getItem("userDetails"));
    let userId = this.props.match.params.id;
    if (agentId && userId === agentId.id) {
      this.setState({
        EditIcon: true,
      });
    } else {
      this.setState({
        EditIcon: false,
      });
    }
  }

  handleFavorites = async () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const favoriteHomeId = this.props.value.id;
    const data = Object.assign(
      {},
      {
        entityData: {
          userId: userDetails.id,
          leadId: favoriteHomeId,
          data: this.props.value,
          mls: this.props.value.mls,
          price:
            window.location.pathname === "/homes" && this.props.value.mls
              ? this.props.value.listPrice !== null
                ? this.props.value.listPrice
                : this.props.value.minPrice !== null
                ? this.props.value.minPrice
                : 0
              : this.props.value.cost !== null
              ? this.props.value.cost
              : this.props.value.minPrice !== null
              ? this.props.value.minPrice
              : 0,
          date: moment(this.props.value.listDate).format("MMM Do YYYY"),
          stateType: this.props.value && this.props.value.stateName,
          leadStatus:
            this.props.value.status !== undefined
              ? this.props.value.status
              : "",
        },
      }
    );

    const postFavorites = await add("userFavorites", data);
    if (postFavorites.success) {
      message.success(
        <>
          <span>
            Listing added to favorites.<br></br>Click on the heart symbol in the
            top menu to see your favorite listings
          </span>
        </>
      );
      this.hidePopOver();
      this.homeAnalytics(true, favoriteHomeId);

      let mlsType = "";
      let filterItems =
        this.props.context.state && this.props.context.state.filterSearchData
          ? this.props.context.state.filterSearchData
          : {
              filter: true,
              sortOrder: "-listprice",
            };
      console.log(filterItems, "jjj");

      if (window.location.pathname === "/homes") {
        mlsType = "both";
      } else {
        mlsType = "off";
      }
      this.props.getHomesList(filterItems, mlsType, "homeCard");
    }
  };

  handleDetailsAfterDeleteFav = async (id) => {
    this.hidePopOver();
    this.homeAnalytics(false, id);
    let mlsType = "";
    const filterItems =
      this.props.context.state && this.props.context.state.filterSearchData
        ? this.props.context.state.filterSearchData
        : {
            filter: true,
            sortOrder: "-listprice",
          };
    if (window.location.pathname === "/homes") {
      mlsType = "both";
    } else {
      mlsType = "off";
    }
    if (
      window.location.pathname === `/favourties/${this.props.match.params.id}`
    ) {
      this.props.getFourites();
    } else {
      this.props.getHomesList(filterItems, mlsType, "homeCard");
    }
  };
  homeAnalytics = async (isFav, id) => {
    const favData = {
      entityData: {
        listingId: id,
        listingFavorites: isFav ? true : false,
      },
    };
    try {
      const response = await noAuthadd("homesAnalytics", favData);
      console.log(response);
    } catch (error) {
      console.log("Error :", error);
    }
  };

  openNotification = () => {
    notification.info({
      placement: "topLeft",
      message:
        "Please create your free account today to save your favorite listings and be notified of new listings",
      style: {
        fontWeight: "500",
      },
    });
    this.setState({
      enableSignupForm: true,
    });
  };

  shareThisHome = (e) => {
    const shareUrlTitile = e.title.split(",")[1];
    const shareUrl = `${process.env.REACT_APP_API_GATEWAY}/noAuth/getHomes/${e.id}/${e.stateName}`;
    const homeDetailShareUrl = `${process.env.REACT_APP_BASE_URL}/homeDetails/${e.id}/${e.stateName}`;
    this.setState({
      shareUrlTitile,
      shareUrl,
      homeDetailShareUrl
    });
  };

  handleLink = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.context.handleToSetCurrentPage(
      this.props.currentPage,
      this.props.perpageSize
    );
  };

  handleEditHome = async () => {
    const {
      privateDetails,
      propertyDetails,
      propertyFeatures,
      propertyDetails2,
    } = this.props.value.editHomesData;
    if (privateDetails) {
      await this.props.contextProps.setHomeDetails(
        privateDetails,
        "PRIVATE_DETAILS"
      );
    }
    if (propertyDetails) {
      await this.props.contextProps.setHomeDetails(
        propertyDetails,
        "PROPERTY_DETAILS"
      );
    }
    if (propertyFeatures) {
      await this.props.contextProps.setHomeDetails(
        propertyFeatures,
        "PROPERTY_FEATURES"
      );
    }
    if (propertyDetails2) {
      await this.props.contextProps.setHomeDetails(
        propertyDetails2,
        "PROPERTY_DETAILS2"
      );
    }
  };

  cancel = (e) => {
    console.log(e);
  };

  handleToshowLoginSider = () => {
    this.setState({
      showLoginSiderForm: true,
    });
  };

  handletoShowSignupsider = () => {
    this.setState({
      showLoginSiderForm: false,
    });
  };

  handleReset = () => {
    this.setState({
      enableSignupForm: false,
      popOverVisible: false,
    });
  };

  handleVisibleChange = () => {
    this.setState({
      popOverVisible: true,
    });
  };

  hidePopOver = () => {
    this.setState({
      popOverVisible: false,
    });
  };
  handle_to_copy_property_url = () => {
    notification["success"]({
      message: "Link copied to clipboard",
    });
  };
  render() {
    const { showLoginSiderForm, homeDetailShareUrl } = this.state;
    let userLogin = localStorage.getItem("userLogin");
    let userInfo = {};
    const loginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUserDetails && loginUserDetails.id) {
      const loginUserId = loginUserDetails.id;
      const loginUserName =
        loginUserDetails.firstName + " " + loginUserDetails.lastName;
      userInfo = {
        userId: loginUserId,
        userName: loginUserName,
        userProfile: loginUserDetails.userProfile,
        preferedAgent:
          loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentEmail,
        prefereAgentName:
          loginUserDetails.agentInfo && loginUserDetails.agentInfo.agentName,
      };
    }

    let HomeId = this.props && this.props.value && this.props.value.id;
    let favouriteLeadId =
      this.props &&
      this.props.value &&
      this.props.value.favorites &&
      this.props.value.favorites.length > 0 &&
      this.props.value.favorites[0].leadId;

    const content = (
      <div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            onClick={() => {
              window.FB.ui(
                {
                  display: "popup",
                  method: "share",
                  href: this.state.shareUrl,
                },
                function (response) {}
              );
            }}
            href={() => false}
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <FacebookOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; facebook
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://api.whatsapp.com/send?text=${homeDetailShareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <WhatsAppOutlined style={{ fontSize: 18 }} />
            &nbsp;&nbsp; Whatsapp
          </a>
        </div>
        <div className="share-to-social-media">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?text=${homeDetailShareUrl}`}
            style={{ textDecoration: "none" }}
          >
            <TwitterOutlined style={{ fontSize: 18 }} /> &nbsp;&nbsp; Twitter
          </a>
        </div>
        <div className="share-to-social-media">
          <CopyToClipboard
            text={homeDetailShareUrl}
            onCopy={this.handle_to_copy_property_url}
          >
            <span style={{ color: "#1890ff" }}>
              <CopyOutlined />
              &nbsp;&nbsp;&nbsp;Copy URL
            </span>
          </CopyToClipboard>
        </div>
      </div>
    );

    const shareAndLikeOptions = (
      <Row>
        <div
          className={
            this.props.value.homesList
              ? "side-favorites-homeList"
              : "side-favorites"
          }
        >
          <div
            className="column1 left center-item"
            onClick={() => this.shareThisHome(this.props.value)}
            style={{ cursor: "pointer" }}
          >
            <Popover
              trigger="click"
              content={content}
              title="Share this Home"
              onClick={this.hidePopOver}
            >
              <div
                style={{
                  marginLeft: "2px",
                  marginTop: "4px",
                }}
              >
                <ShareAltOutlined className="share" />
              </div>
            </Popover>
          </div>
          <div>
            <Divider type="vertical" className="divider-favorite" />
          </div>
          <div className="column1 middle center-item">
            <div
              style={{
                marginLeft: "2px",
                marginTop: "4px",
              }}
            >
              {window.location.pathname !==
                `/favourties/${this.props.match.params.id}` &&
              userLogin &&
              HomeId === favouriteLeadId ? (
                <DeleteRecord
                  recordId={
                    this.props &&
                    this.props.value &&
                    this.props.value.favorites &&
                    this.props.value.favorites.length > 0 &&
                    this.props.value.favorites[0].id
                  }
                  apiPath={"userFavorites"}
                  getHomesList={this.handleDetailsAfterDeleteFav}
                  deletionType={"HeartFilled"}
                  deletionSuccessMessage={"Removed from favorites list"}
                />
              ) : (
                window.location.pathname !==
                  `/favourties/${this.props.match.params.id}` && (
                  <HeartOutlined
                    className="homeSearch heart"
                    onClick={
                      userLogin ? this.handleFavorites : this.openNotification
                    }
                  />
                )
              )}

              {window.location.pathname ===
                `/favourties/${this.props.match.params.id}` &&
                this.props.value &&
                this.props.value.favorited && (
                  <DeleteRecord
                    recordId={
                      this.props &&
                      this.props.value &&
                      this.props.value.favouriteId &&
                      this.props.value.favouriteId
                    }
                    apiPath={"userFavorites"}
                    getHomesList={this.handleDetailsAfterDeleteFav}
                    deletionType={"HeartFilled"}
                    deletionSuccessMessage={"Removed from favorites list"}
                  />
                )}
            </div>
          </div>

          {this.state.enableSignupForm && (
            <Drawer
              className="homecard-drawer"
              title=""
              placement="right"
              width={window.screen.width >= "768" ? "40%" : "100%"}
              mask={true}
              closable={true}
              visible={this.state.enableSignupForm}
              onClose={() => this.handleReset()}
              headerStyle={{ textAlign: "center" }}
              bodyStyle={{
                paddingBottom: 80,
                backgroundColor: "#eff2f5",
                padding: "0px",
              }}
            >
              <Row>
                <Col span={24}>
                  {showLoginSiderForm ? (
                    <LoginForm
                      showAdd={false}
                      modalTrue
                      goTopreviousPage={true}
                      handleToshowLoginSider={this.handletoShowSignupsider}
                      showLoginSiderForm={showLoginSiderForm}
                      questionPage
                    />
                  ) : (
                    <SignupForm
                      questionPage
                      showAdd={false}
                      modalTrue
                      goTopreviousPage={true}
                      handleToshowLoginSider={this.handleToshowLoginSider}
                      showLoginSiderForm={showLoginSiderForm}
                    />
                  )}
                </Col>
              </Row>
            </Drawer>
          )}

          {(this.props.value && this.props.value.homesList) ||
          this.props.value.mlsList ? (
            ""
          ) : (
            <>
              <div>
                <Divider type="vertical" className="divider-favorite" />
              </div>
              <div
                style={{
                  margin: "5px 10px 0px 2px",
                  width: "auto",
                }}
                onClick={this.hidePopOver}
              >
                <SendMessage
                  msgButtonTitle="Message"
                  toName={
                    this.props.value.agent !== "undefined undefined" &&
                    this.props.value.agent !== ""
                      ? this.props.value.agent
                      : ""
                  }
                  propertyId={this.props.value && this.props.value.id}
                  propertyTitle={
                    this.props.value.title !== ""
                      ? this.props.value.title
                      : "enter your message"
                  }
                  propertyImages={this.props.value.photos}
                  buttonAlign="listing"
                  mlsId={
                    this.props.value && this.props.value.mlsId
                      ? this.props.value.mlsId
                      : this.props.value.id
                  }
                  agentEmail={this.props.value && this.props.value.agentEmail}
                  messageType="potential"
                  isRegistredUser={
                    this.props && this.props.value && this.props.value.mls
                      ? false
                      : true
                  }
                  stateType={
                    this.props &&
                    this.props.value &&
                    this.props.value.mls === false
                      ? "offMls"
                      : this.props &&
                        this.props.value &&
                        this.props.value.stateName
                  }
                  preferedAgent={
                    userInfo.preferedAgent && userInfo.preferedAgent
                  }
                  prefereAgentName={
                    userInfo.prefereAgentName && userInfo.prefereAgentName
                  }
                  buyerLoginSendMessage={
                    userInfo &&
                    (userInfo.userProfile === "B" ||
                      userInfo.userProfile === "S")
                      ? true
                      : false
                  }
                  homeCardMsg={true}
                />
              </div>
            </>
          )}
        </div>
      </Row>
    );
    return (
      <div
        data-aos-offset={this.props.showMatchesPage ? "0" : "100"}
        data-aos-delay={this.props.showMatchesPage ? "0" : "200"}
        data-aos-once={this.props.showMatchesPage ? "false" : "true"}
        data-aos={this.props.showMatchesPage ? "" : "fade-up"}
        data-aos-easing={this.props.showMatchesPage ? "" : "ease-in"}
      >
        <Layout
          style={{
            backgroundColor:
              this.props.value.homesList ||
              this.props.value.mlsList ||
              this.props.showColor
                ? "white"
                : "",
          }}
        >
          <Row
            className="home-card-space-bw"
            style={{ background: "rgb(240 242 245)" }}
          >
            <Col
              xl={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="ant-card-hoverable"
              style={{ borderRadius: "5px" }}
            >
              <Row style={{ background: "white", borderRadius: "5px" }}>
                <Col
                  xl={{
                    span:
                      this.props.value.homesList || this.props.value.mlsList
                        ? 5
                        : 4,
                    offset: 0,
                  }}
                  lg={{
                    span:
                      this.props.value.homesList || this.props.value.mlsList
                        ? 5
                        : 4,
                    offset: 0,
                  }}
                  md={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                  style={{ backgroundColor: "#d4d8f1fa", borderRadius: "5px" }}
                >
                  <Link
                    className="no-lines"
                    to={{
                      pathname: `/homeDetails/${this.props.value.stateName}/${
                        this.props.value.openHouseKey
                          ? this.props.value.openHouseKey
                          : this.props.value.id
                      }`,
                      state:
                        (this.props.match.path === "/homes" ||
                          this.props.match.path === "/offMLSHomes") &&
                        true,
                    }}
                    onClick={this.handleLink}
                  >
                    <img
                      className="img-fit-mobile"
                      src={
                        this.props.value.photos
                          ? this.props.value.photos
                          : defaultImage
                      }
                      alt="space"
                    />

                    {this.props.value.homesList && (
                      <div className="when-to-added">
                        Added {moment(this.props.value.createdAt).fromNow()}
                      </div>
                    )}
                  </Link>
                </Col>
                <Col
                  xl={{
                    span:
                      this.props.value.homesList || this.props.value.mlsList
                        ? 19
                        : 20,
                  }}
                  lg={{
                    span:
                      this.props.value.homesList || this.props.value.mlsList
                        ? 19
                        : 20,
                  }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ padding: "5px", paddingTop: "10px" }}
                >
                  <Row>
                    <Col
                      xl={{
                        span:
                          this.props.value.homesList || this.props.value.mlsList
                            ? 24
                            : 24,
                      }}
                      lg={{
                        span:
                          this.props.value.homesList || this.props.value.mlsList
                            ? 24
                            : 24,
                      }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 17, offset: 0 }}
                          lg={{ span: 17, offset: 0 }}
                          md={{ span: 16, offset: 1 }}
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                        >
                          <Link
                            className="no-lines"
                            to={{
                              pathname: `/homeDetails/${
                                this.props.value.stateName
                              }/${
                                this.props.value.openHouseKey
                                  ? this.props.value.openHouseKey
                                  : this.props.value.id
                              }`,
                              state:
                                (this.props.match.path === "/homes" ||
                                  this.props.match.path === "/offMLSHomes") &&
                                true,
                            }}
                            onClick={this.handleLink}
                          >
                            <Row style={{ marginLeft: "10px" }}>
                              <span
                                className="searchHomes-titles"
                                style={{ textTransform: "capitalize" }}
                              >
                                {this.props.value.title}
                              </span>
                            </Row>
                            {this.props &&
                            this.props.value &&
                            this.props.value.postType !== "" &&
                            this.props.value.postType !== null &&
                            this.props.value.postType !== undefined ? (
                              <>
                                <Row
                                  style={{
                                    marginLeft: "10px",
                                    paddingTop: "1%",
                                    paddingBottom: "1%",
                                  }}
                                  className="hide-post-type-mobile"
                                >
                                  <img
                                    src={privatePost}
                                    alt="postType"
                                    width="12px"
                                    height="14px"
                                    style={{ marginTop: 2 }}
                                  />
                                  <p
                                    style={{
                                      marginLeft: "8px",
                                      color: "#414555",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {this.props.value.postType}
                                  </p>
                                </Row>
                                {this.props.value &&
                                  this.props.value.openHouseDate && (
                                    <Row
                                      className="hide-post-type-mobile"
                                      style={{ paddingBottom: "1em" }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#1890ff",
                                          fontSize: "18px",
                                          marginBottom: "0px",
                                          fontWeight: "600",
                                          fontFamily: "Poppins, sans-serif",
                                        }}
                                      >
                                        Open House:
                                      </p>
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#1890ff",
                                          fontSize: "18px",
                                          marginBottom: "0px",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {this.props.value &&
                                          this.props.value.multiOpenHouses &&
                                          this.props.value.multiOpenHouses
                                            .length > 0 &&
                                          this.props.value.multiOpenHouses.map(
                                            (eachDate, index) => (
                                              <>
                                                {moment
                                                  .utc(eachDate.startTime)
                                                  .format("MMM Do YYYY h:mm a")}
                                                {eachDate.endTime && (
                                                  <>
                                                    {" - "}
                                                    {moment
                                                      .utc(eachDate.endTime)
                                                      .format("LT")}
                                                  </>
                                                )}
                                                {this.props.value
                                                  .multiOpenHouses.length -
                                                  1 !==
                                                  index && ", "}{" "}
                                              </>
                                            )
                                          )}
                                      </p>
                                    </Row>
                                  )}
                                {this.props.value &&
                                  this.props.value.openHouseDescription && (
                                    <Row
                                      className="hide-post-type-mobile"
                                      style={{ paddingBottom: "1em" }}
                                    >
                                      <p
                                        style={{
                                          marginLeft: "8px",
                                          color: "#414555",
                                          fontSize: "16px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {this.props.value.openHouseDescription}
                                      </p>
                                    </Row>
                                  )}
                                {this.props.value && this.props.value.status && (
                                  <Row
                                    className="hide-post-type-mobile"
                                    style={{ paddingBottom: "1em" }}
                                  >
                                    <p
                                      style={{
                                        marginLeft: "8px",
                                        color: "#99a2c3",
                                        fontSize: "16px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Status:
                                    </p>
                                    <p
                                      style={{
                                        marginLeft: "8px",
                                        color: "#414555",
                                        fontSize: "16px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {this.props.value.mls
                                        ? this.props.value.status &&
                                          this.props.value.status
                                        : this.props.value.status &&
                                          this.props.value.status.length > 0 &&
                                          this.props.value.status.includes(
                                            "comingsoon"
                                          ) &&
                                          this.props.value.status.length === 1
                                        ? "Coming Soon"
                                        : this.props.value.status.includes(
                                            "active"
                                          ) &&
                                          this.props.value.status.length === 1
                                        ? "New Listing"
                                        : this.props.value.status.includes(
                                            "pending"
                                          ) &&
                                          this.props.value.status.includes(
                                            "activeundercontract"
                                          ) &&
                                          this.props.value.status.length === 2
                                        ? "Under Agreement"
                                        : this.props.value.status.includes(
                                            "active"
                                          ) &&
                                          this.props.value.status.includes(
                                            "activeundercontract"
                                          ) &&
                                          this.props.value.status.includes(
                                            "comingsoon"
                                          )
                                        ? "For Sale (On MLS)"
                                        : this.props.value.status.includes(
                                            "For Sale (Off MLS)"
                                          )
                                        ? "For Sale (Off MLS)"
                                        : this.props.value.status.includes(
                                            "For Sale by Owner"
                                          )
                                        ? "For Sale by Owner"
                                        : this.props.value.status.includes(
                                            "Temp off MLS"
                                          ) && "Temp off MLS"}
                                    </p>
                                  </Row>
                                )}
                                {this.props.value &&
                                  this.props.value.homesList &&
                                  window.location.pathname ===
                                    `/viewProfile/${this.props.match.params.id}` && (
                                    <Row style={{ paddingBottom: "1%" }}>
                                      <div className="layout-last-step">
                                        <span>
                                          <p
                                            className="mobile-view-padding-down"
                                            style={{
                                              fontSize: "14px",
                                              color: "#000",
                                              paddingLeft: "10px",
                                              marginBottom: "0",
                                            }}
                                          >
                                            {this.props.value &&
                                              this.props.value.homesList &&
                                              window.location.pathname ===
                                                `/viewProfile/${this.props.match.params.id}` &&
                                              this.props.value.labels !== "" &&
                                              typeof this.props.value.labels ===
                                                "string" &&
                                              this.props.value.labels}
                                            {this.props.value &&
                                              this.props.value.homesList &&
                                              window.location.pathname ===
                                                `/viewProfile/${this.props.match.params.id}` &&
                                              this.props.value.labels &&
                                              this.props.value.labels.length >
                                                0 &&
                                              typeof this.props.value.labels !==
                                                "string" &&
                                              this.props.value.labels.join(
                                                ", "
                                              )}
                                          </p>
                                        </span>
                                      </div>
                                    </Row>
                                  )}
                              </>
                            ) : (
                              <></>
                            )}
                            {this.props &&
                              this.props.value &&
                              this.props.value.mlsList && (
                                <Row style={{ marginRight: "10px" }}>
                                  <span className="searchHomes-titles">
                                    &nbsp;{" "}
                                    <NumberFormat
                                      value={parseFloat(
                                        this.props.value.mls
                                          ? this.props.value.listPrice
                                          : this.props.value.cost
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </span>
                                </Row>
                              )}

                            {this.props &&
                              this.props.value &&
                              this.props.value.homesList && (
                                <>
                                  {this.props && this.props.value.cost ? (
                                    <Row style={{ marginRight: "10px" }}>
                                      <span className="searchHomes-titles">
                                        &nbsp;{" "}
                                        <NumberFormat
                                          value={parseFloat(
                                            this.props.value.cost
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      </span>
                                    </Row>
                                  ) : (
                                    <Row style={{ marginRight: "10px" }}>
                                      <span className="searchHomes-titles">
                                        &nbsp;{" "}
                                        <NumberFormat
                                          value={parseFloat(
                                            this.props.value.minPrice
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />{" "}
                                        -
                                        <NumberFormat
                                          value={parseFloat(
                                            this.props.value.maxPrice
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />{" "}
                                      </span>
                                    </Row>
                                  )}
                                </>
                              )}
                          </Link>
                        </Col>
                        <Col
                          xl={{ span: 7 }}
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                        >
                          {this.props.value.homesList ||
                          this.props.value.mlsList ? (
                            <Col
                              span={8}
                              offset={16}
                              onClick={this.handleEditHome}
                              className="hide-desktop-version-none"
                            >
                              {this.state.EditIcon && !this.props.value.mls && (
                                <Row>
                                  <Col span={12}>
                                    <Link
                                      to={
                                        this.props.value.sellerHomeAdded
                                          ? {
                                              pathname: `/homeDetails/${
                                                this.props.value.stateName
                                              }/${
                                                this.props.value.openHouseKey
                                                  ? this.props.value
                                                      .openHouseKey
                                                  : this.props.value.id
                                              }`,
                                              state:
                                                this.props.value &&
                                                this.props.value,
                                            }
                                          : {
                                              pathname: `/add/sellerProfile/${this.props.value.id}`,
                                              state:
                                                this.props.value &&
                                                this.props.value,
                                            }
                                      }
                                    >
                                      <EditTwoTone
                                        style={{
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Link>
                                  </Col>
                                  <Col span={12}>
                                    <DeleteRecord
                                      recordId={this.props.value.id}
                                      apiPath={"homesList"}
                                      getHomesList={this.props.getHomesList}
                                      fontSizeProp={"18px"}
                                      deletionType={"DeleteOutlined"}
                                      deletionSuccessMessage={
                                        "Your record deleted successfully"
                                      }
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          ) : (
                            <>
                              <Row>
                                <Col
                                  xs={{ span: 14 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  sm={{ span: 14 }}
                                >
                                  {!this.props.value.mlsList && (
                                    <Row style={{ marginLeft: "10px" }}>
                                      <span className="searchHomes-titles">
                                        <NumberFormat
                                          value={parseFloat(
                                            this.props.value.mls
                                              ? this.props.value.listPrice
                                              : this.props.value.cost
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />{" "}
                                      </span>
                                    </Row>
                                  )}
                                </Col>
                                <Col
                                  xs={{ span: 10 }}
                                  lg={{ span: 24 }}
                                  md={{ span: 24 }}
                                  xl={{ span: 24 }}
                                  sm={{ span: 10 }}
                                >
                                  <Row className="home-card-date">
                                    <div className="layout-last-step">
                                      <span className="layout-subtitles">
                                        <p
                                          className="mobile-view-padding-down"
                                          style={{
                                            fontSize: "14px",
                                            marginRight: "10px",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {this.props.value.date
                                            ? this.props.value.date
                                            : moment(
                                                this.props.value.listDate
                                              ).format("MMM Do YYYY")}
                                        </p>
                                      </span>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xl={{
                        span:
                          this.props.value.homesList || this.props.value.mlsList
                            ? 24
                            : 24,
                      }}
                      lg={{
                        span:
                          this.props.value.homesList || this.props.value.mlsList
                            ? 24
                            : 24,
                      }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Row>
                        <Col
                          xl={{ span: 17, offset: 0 }}
                          lg={{ span: 17, offset: 0 }}
                          md={{ span: 16, offset: 1 }}
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                        >
                          <Link
                            className="no-lines"
                            to={{
                              pathname: `/homeDetails/${
                                this.props.value.stateName
                              }/${
                                this.props.value.openHouseKey
                                  ? this.props.value.openHouseKey
                                  : this.props.value.id
                              }`,
                              state:
                                (this.props.match.path === "/homes" ||
                                  this.props.match.path === "/offMLSHomes") &&
                                true,
                            }}
                            onClick={this.handleLink}
                          >
                            {!this.props.value.homesList &&
                              this.props.value.type && (
                                <Row>
                                  <div className="layout-last-step">
                                    <span>
                                      <p
                                        className="mobile-view-padding-down"
                                        style={{
                                          fontSize: "14px",
                                          color: "#000",
                                          paddingLeft: "10px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {!this.props.value.homesList &&
                                        this.props.value.type &&
                                        this.props.value.type === "LND"
                                          ? "Land"
                                          : this.props.value.type === "MBL"
                                          ? "Mobile Homes"
                                          : this.props.value.type === "MLF"
                                          ? "Residential Multi Family Home"
                                          : this.props.value.type === "RES"
                                          ? "Residential"
                                          : this.props.value.type === "RNT"
                                          ? "Residential Rental"
                                          : this.props.value.type === "CND"
                                          ? "Condominium"
                                          : this.props.value.type === "CRE"
                                          ? "Commercial"
                                          : this.props.value.type}
                                      </p>
                                    </span>
                                  </div>
                                </Row>
                              )}
                            <Row style={{ paddingBottom: "1%" }}>
                              <div className="layout-last-step">
                                <span>
                                  <p
                                    className="mobile-view-padding-down"
                                    style={{
                                      fontSize: "14px",
                                      color: "#000",
                                      paddingLeft: "10px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {this.props.value &&
                                      !this.props.value.homesList &&
                                      this.props.value.labels !== "" &&
                                      typeof this.props.value.labels ===
                                        "string" &&
                                      this.props.value.labels}
                                    {this.props.value &&
                                      !this.props.value.homesList &&
                                      this.props.value.labels &&
                                      this.props.value.labels.length > 0 &&
                                      typeof this.props.value.labels !==
                                        "string" &&
                                      this.props.value.labels.join(", ")}
                                  </p>
                                </span>
                              </div>
                            </Row>
                            <Row style={{ marginLeft: "10px" }}>
                              <Col span={24}>
                                <Row>
                                  <div className="layout-last-step">
                                    <span>
                                      &nbsp;
                                      <img
                                        src={bedIcon}
                                        alt="space"
                                        width="18px"
                                        height="10px"
                                      />
                                    </span>
                                    <span className="layout-subtitles">
                                      <p className="beds-size-mobile">
                                        {this.props.value.beds === null ||
                                        this.props.value.beds === ""
                                          ? 0
                                          : this.props.value.beds}{" "}
                                        Beds
                                      </p>
                                    </span>
                                  </div>
                                  <div
                                    className="layout-last-step"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <span>
                                      <img
                                        src={bathIcon}
                                        alt="space"
                                        width="18px"
                                        height="10px"
                                      />
                                    </span>
                                    <span className="layout-subtitles">
                                      <p
                                        style={{ fontSize: "16px" }}
                                        className="beds-size-mobile"
                                      >
                                        {this.props.value.baths === null ||
                                        this.props.value.baths === ""
                                          ? 0
                                          : this.props.value.baths}
                                        {this.props.value.halfBaths > 0
                                          ? ".5"
                                          : ""}{" "}
                                        Baths
                                      </p>
                                    </span>
                                  </div>

                                  <div
                                    className="layout-last-step"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <span>
                                      <img
                                        src={reSize}
                                        alt="space"
                                        width="16px"
                                        height="10px"
                                      />
                                    </span>

                                    <span className="layout-subtitles">
                                      <p
                                        style={{ fontSize: "16px" }}
                                        className="beds-size-mobile"
                                      >
                                        {this.props.value.area &&
                                        (this.props.value.area !== null ||
                                          this.props.value.area !== undefined ||
                                          this.props.value.area !== "" ||
                                          this.props.value.area <= 99999)
                                          ? `${this.props.value.area} sq.ft`
                                          : "NA"}
                                      </p>
                                    </span>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Link>
                        </Col>
                        <Col
                          xl={{ span: 7 }}
                          lg={{ span: 7 }}
                          md={{ span: 7 }}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                        >
                          <Row>
                            <Col
                              xs={{ span: 18 }}
                              lg={{ span: 24 }}
                              xl={{ span: 24 }}
                              md={{ span: 24 }}
                              sm={{ span: 18 }}
                            >
                              {this.props.value.homesList ||
                              this.props.value.mlsList ? (
                                <Col
                                  xs={{ span: 18 }}
                                  md={{ span: 24 }}
                                  sm={{ span: 18 }}
                                  onClick={this.handleEditHome}
                                  className="edit-delete-option-mobile"
                                >
                                  {this.state.EditIcon && (
                                    <Row>
                                      <Col span={12}>
                                        <Link
                                          to={
                                            this.props.value.sellerHomeAdded
                                              ? {
                                                  pathname: `/add/userHomeForm/${this.props.value.id}`,
                                                  state:
                                                    this.props.value &&
                                                    this.props.value,
                                                }
                                              : {
                                                  pathname: `/add/sellerProfile/${this.props.value.id}`,
                                                  state:
                                                    this.props.value &&
                                                    this.props.value,
                                                }
                                          }
                                        >
                                          <EditTwoTone
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Link>
                                      </Col>
                                      <Col span={12}>
                                        <DeleteRecord
                                          recordId={this.props.value.id}
                                          apiPath={"homesList"}
                                          getHomesList={this.props.getHomesList}
                                          fontSizeProp={"18px"}
                                          deletionType={"DeleteOutlined"}
                                          deletionSuccessMessage={
                                            "Your record deleted successfully"
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              ) : (
                                <>
                                  <Row
                                    style={{
                                      paddingBottom: "1%",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div className="layout-last-step">
                                      <span>
                                        {this.props.value &&
                                        this.props.value.homeAgentProfileUrl ? (
                                          <img
                                            src={
                                              this.props.value
                                                .homeAgentProfileUrl
                                            }
                                            alt="space"
                                            width="40px"
                                            height="40px"
                                            style={{
                                              borderRadius: "50%",
                                              marginRight: "8px",
                                            }}
                                          />
                                        ) : this.props.value.userRegisterd &&
                                          this.props.value.userRegisterd
                                            .length > 0 &&
                                          this.props.value.userRegisterd[0] ? (
                                          <img
                                            src={
                                              this.props.value.userRegisterd[0]
                                                .profileUrl
                                            }
                                            alt="space"
                                            width="40px"
                                            height="40px"
                                            style={{
                                              borderRadius: "50%",
                                              marginRight: "8px",
                                            }}
                                          />
                                        ) : (
                                          <h5
                                            style={{
                                              height: "32px",
                                              width: "32px",
                                              borderRadius: "16px",
                                              backgroundColor: "#99A2C3",
                                              textAlign: "center",
                                              color: "#000",
                                              fontSize: "20px",
                                              marginRight: "8px",
                                            }}
                                          >
                                            {this.props.value.agent !==
                                              "undefined undefined" &&
                                              this.props.value.agent &&
                                              this.props.value.agent
                                                .charAt(0)
                                                .toUpperCase()}
                                          </h5>
                                        )}
                                      </span>
                                      <span className="layout-subtitles">
                                        {(this.props.value.mls &&
                                          this.props.value.userid) ||
                                        !this.props.value.mls ? (
                                          <Link
                                            to={`/viewProfile/${
                                              this.props &&
                                              this.props.value &&
                                              this.props.value.userid
                                            }`}
                                            style={{ textDecoration: "none" }}
                                            onClick={this.handleLink}
                                          >
                                            <p
                                              style={{
                                                fontSize: "16px",
                                                marginTop: "5px",
                                                color: "#3045D2",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {this.props.value.agent !==
                                                "undefined undefined" &&
                                                this.props.value.agent}
                                            </p>
                                          </Link>
                                        ) : (
                                          <Link
                                            to={{
                                              pathname:
                                                this.props.value
                                                  .userRegisterd &&
                                                this.props.value.userRegisterd
                                                  .length > 0 &&
                                                this.props.value
                                                  .userRegisterd[0]
                                                  ? `/viewProfile/${
                                                      this.props &&
                                                      this.props.value &&
                                                      this.props.value
                                                        .userRegisterd[0].id
                                                    }`
                                                  : `/homeDetails/${
                                                      this.props.value.stateName
                                                    }/${
                                                      this.props.value
                                                        .openHouseKey
                                                        ? this.props.value
                                                            .openHouseKey
                                                        : this.props.value.id
                                                    }`,
                                              state:
                                                (this.props.match.path ===
                                                  "/homes" ||
                                                  this.props.match.path ===
                                                    "/offMLSHomes") &&
                                                true,
                                            }}
                                            style={{ textDecoration: "none" }}
                                            onClick={this.handleLink}
                                          >
                                            <p
                                              style={{
                                                fontSize: "16px",
                                                marginTop: "8px",
                                                color: "#3045D2",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {this.props.value.userRegisterd &&
                                              this.props.value.userRegisterd
                                                .length > 0 &&
                                              this.props.value.userRegisterd[0]
                                                ? this.props.value
                                                    .userRegisterd[0]
                                                    .firstName +
                                                  "" +
                                                  this.props.value
                                                    .userRegisterd[0].lastName
                                                : this.props.value.agent !==
                                                    "undefined undefined" &&
                                                  this.props.value.agent}
                                            </p>
                                          </Link>
                                        )}
                                      </span>
                                    </div>
                                  </Row>
                                </>
                              )}
                              <Row style={{ paddingBottom: "1%" }}>
                                <div className="layout-last-step">
                                  <span>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                        paddingLeft: "10px",
                                      }}
                                    ></p>
                                  </span>
                                </div>
                              </Row>
                            </Col>
                            <Col
                              xs={{ span: 4, offset: 2 }}
                              lg={{ span: 24, offset: 0 }}
                              xl={{ span: 24, offset: 0 }}
                              md={{ span: 24, offset: 0 }}
                              sm={{ span: 4, offset: 2 }}
                              style={{ padding: "10px" }}
                            >
                              <Row
                                style={{
                                  marginTop: "5px",
                                  float:
                                    this.props.value.homesList ||
                                    this.props.value.mlsList
                                      ? "right"
                                      : "",
                                }}
                              >
                                <Popover
                                  placement="bottomRight"
                                  title={null}
                                  content={shareAndLikeOptions}
                                  trigger="click"
                                  className={
                                    this.props.value.homesList ||
                                    this.props.value.mlsList
                                      ? "show-on-profile-details-mobile"
                                      : "showing-sharing-like"
                                  }
                                  visible={this.state.popOverVisible}
                                  onVisibleChange={this.handleVisibleChange}
                                >
                                  <EllipsisOutlined
                                    style={{ fontSize: "30px" }}
                                  />
                                </Popover>
                                <Col
                                  span={24}
                                  className={
                                    this.props.value.homesList ||
                                    this.props.value.mlsList
                                      ? "show-on-profile-details"
                                      : "hide-sharing-like"
                                  }
                                >
                                  {shareAndLikeOptions}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      </div>
    );
  }
}

export default withAppContext(withRouter(HomeCard));
